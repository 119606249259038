<template>
  <v-container
    id="container"
    class="fill-height"
    fluid>
    <v-row
      align="center"
      justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="3">
        <v-card
          id="login-card"
          class="elevation-12"
          :loading="loading">
          <v-toolbar
            color="secondary"
            dark
            flat>
            <v-toolbar-title>{{ $t("actions.login") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <validation-observer
              ref="validationObserver">
              <v-form @submit.prevent="login">
                <div v-if="isFireTv">
                  <h1 class="text-center">
                    {{ $t("actions.waiting_for_setup") }}
                  </h1>
                  <h1 class="text-center">
                    {{ code }}
                  </h1>
                </div>
                <div v-else>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="username">
                    <v-text-field
                      v-model="form.username"
                      :label="$t('attributes.users.username')"
                      name="username"
                      :error-messages="errors[0]"
                      type="text"
                      hide-details="auto"
                      autofocus>
                      <template #prepend>
                        <font-awesome-icon icon="user" />
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="password">
                    <v-text-field
                      id="password"
                      v-model="form.password"
                      name="password"
                      type="password"
                      :label="$t('attributes.users.password')"
                      :error-messages="errors[0]">
                      <template #prepend>
                        <font-awesome-icon icon="lock" />
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                  <v-btn
                    block
                    class="mt-3"
                    type="submit"
                    color="primary"
                    :disabled="loading"
                    @click="login">
                    {{ $t("actions.login") }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOGIN_USER as LOGIN_ACTION } from "../../store/actions.js";
import {
  SET_LANGUAGE,
  SET_BETTING_POOL,
  SET_SESSION_MODE,
  SET_TOKEN,
} from "@/store/mutations.js";
import Validatable from "../../mixins/Validatable.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import LoginRedirectionHelper, { getNextPath } from "@/services/LoginRedirectionHelper.js";
import { ValidationObserver } from "vee-validate";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";
import { URL as WebsocketURL } from "@/services/Websocket.js";

export default {
  name: "SessionCreate",
  components: {
    ValidationObserver,
  },
  mixins: [Validatable, ContentLoader],
  channels: {
    LoginRouletteChannel: {
      connected() {
        console.log("connected");
      },
      rejected() {
        console.error("rejected");
      },
      received({
        type,
        token,
        code,
        message,
      }) {
        switch (type) {
          case "ATTEMPT_LOGIN":
            try {
              this.$startLoading();
              this.$store.commit(SET_TOKEN, { token: token });
              //token and bp info already at store
              this.$store.commit(SET_SESSION_MODE, { mode: SessionCreateCategory.roulette });
              this.$store.commit(SET_LANGUAGE, { lang: this.$store.getters.language });
              this.$store.commit(SET_BETTING_POOL, { bettingPool: this.$store.getters.bettingPool });
              this.$router.replace(getNextPath(this.$router, this.$route, this.$routes, this.$store));
            } catch (error) {
              this.handleErrorResponse(this.$refs["validationObserver"], error);
            } finally {
              this.$finishedLoading();
            }
            break;
          case "SUBSCRIPTION_ESTABLISHED":
            this.code = code;
            break;
          default:
        }
      },
      disconnected() {
        console.log("disconnected");
      },
    },
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      isFireTv: false,
      code: "",
    };
  },
  mounted() {
    this.isFireTv = Boolean(this.$route.query["isFirestick"]) === true;
    this.disconnectWebsocket();
    this.connectWebsocket({ token: "anonymous" });
    this.$cable.subscribe({
      channel: "LoginRouletteChannel",
    });
  },
  methods: {
    async login() {
      const {
          $store,
          form,
          $router,
          $route,
          $routes,
          $refs,
          handleErrorResponse,
        } = this,
        { validationObserver } = $refs;

      try {
        this.$startLoading();
        await $store.dispatch(LOGIN_ACTION, {
          form,
          category: SessionCreateCategory.roulette,
        });
        this.$router.replace(getNextPath($router, $route, $routes, $store));
      } catch (error) {
        handleErrorResponse(validationObserver, error);
      } finally {
        this.$finishedLoading();
      }
    },
  },
};
</script>

<style scoped>
#container {
  background-image: url("../../../public/img/backgrounds/roulette-background.jpg");
  background-size: cover;
}

.container.fill-height > .row {
  max-width: none;
}

#login-card {
  opacity: 0.9;
  margin-bottom: 90%;

}
</style>
